<template>
    <el-container class="order-preview" style="display: block;">
        <el-main class="main">
            <el-row>
                <el-col :span="24"><div class="goods-title">商品信息</div></el-col>
            </el-row>
            <div v-if="form.productId">
                <el-row class="main-row">
                    <el-col :span="3" :align="middle">商品图片：</el-col>
                    <el-col :span="21">
                        <el-image :src="goods.absolute_pic" :lazy="true" :preview-src-list="[goods.absolute_pic]">
                            <slot name="error">
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </slot>
                            <slot name="placeholder">
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </slot>
                        </el-image>
                    </el-col>
                </el-row>
                <el-row class="main-row">
                    <el-col :span="3">商品名称：</el-col>
                    <el-col :span="21">{{goods.goods_name}}</el-col>
                </el-row>
                <el-row class="main-row">
                    <el-col :span="3">商品价格：</el-col>
                    <el-col :span="21"><strong>￥{{goods.price}}</strong></el-col>
                </el-row>
                <el-row class="main-row">
                    <el-col :span="3">商品数量：</el-col>
                    <el-col :span="21">{{form.cartNum}}</el-col>
                </el-row>
                <el-row class="main-row">
                    <el-col :span="3">小计：</el-col>
                    <el-col :span="21">{{goods.price * form.cartNum}}</el-col>
                </el-row>
            </div>
            <div v-else>
                <el-empty description="没有商品信息"></el-empty>
            </div>
        </el-main>
        <el-main class="footer">
            <el-row>
                <el-col :span="24" class="footer-col real-pay">
                    <span class="label">实付款：</span><span class="total-price">￥{{goods.price * form.cartNum}}</span>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" class="footer-col">
                    <span class="label">寄送至：</span>{{form.address}}
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" class="footer-col">
                    <span class="label">收货人：</span>{{form.name}} {{form.mobile}}
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" class="footer-col order-submit">
                    <el-button type="success" @click="createOrderSubmit">提交订单</el-button>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
    
  </template>
  
  <script>
  import { ref, reactive } from 'vue'
  import { ElButton, ElCol, ElContainer, ElEmpty, ElFooter, ElImage, ElMain, ElRow } from 'element-plus';
  import 'element-plus/dist/index.css'
  import { useRoute, useRouter } from 'vue-router'
  import { getProductDetail } from '@/api/product'
  import { createOrder } from '@/api/order'
  
  export default {
    name: 'orderPreview',
    setup() {
        const route = useRoute();
        const router = useRouter();

        const form = reactive({
            productId: route.query.productId,
            address: route.query.address,
            name: route.query.name,
            mobile: route.query.mobile,
            cartNum: route.query.cartNum
        });
  
        // 获取商品详情
        const goods = ref({});
        getProductDetail({ id: form.productId }).then(res => {
            goods.value = res.data
        });
  
        // 创建订单
        const createOrderSubmit = async () => {
            const { data } = await createOrder({ 
                productId: form.productId, 
                cartNum: form.cartNum,
                name: form.name,
                mobile: form.mobile,
                address: form.address,
            })
            await router.push(`/payment/${data.order_sn}`)
        }

        return {
            goods,
            form,
            createOrderSubmit
        }
    },
    components: {
        [ElContainer.name]: ElContainer,
        [ElMain.name]: ElMain,
        [ElFooter.name]: ElFooter,
        [ElImage.name]: ElImage,
        [ElRow.name]: ElRow,
        [ElCol.name]: ElCol,
        [ElButton.name]: ElButton,
        [ElEmpty.name]: ElEmpty
    }
  }
  </script>
  
  <style lang="less" scoped>
    .order-preview {
        .goods-title {
            font-size: 16px;
            font-weight: 700;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #cccccc;
        }
        
        .total-price {
            color: #ff0000;
            font-size: 20px;
        }

        .main-row {
            margin-top: 5px;
        }

        .label {
            font-weight: 700;
        }

        .footer-col {
            text-align: right;
        }

        .real-pay {
            margin-bottom: 10px
        }

        .order-submit {
            margin-top: 10px
        }

        .main, .footer {
            background: white;
        }

        .footer {
            margin-top: 10px;
            padding: 10px 10px 15px 10px;
        }
    }
  </style>